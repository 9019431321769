<template>
  <div>
    <h1>{{ (playlist && playlist.title) || 'Loading...' }}</h1>
    <TrackTable v-if="playlist" :tracks="playlist.tracks" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import TrackTable from '@/components/TrackTable/TrackTable.vue';
import { fetchPlaylistDetail } from '@/api/playlists.js';

export default {
  name: 'Playlist',
  components: {
    TrackTable,
  },
  props: {
    id: { type: String },
  },
  data() {
    return { playlist: null };
  },
  methods: {
    async load() {
      try {
        this.playlist = await fetchPlaylistDetail(this.id);
        this.playlist.tracks = this.playlist.tracks.map((t) => ({
          ...t,
          isPlaying: false,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    onClickedTrackRow(track) {
      this.setCurrentTrack(track);
      this.setIsPlaying(true);
    },
    ...mapMutations(['setCurrentTrack', 'setIsPlaying']),
  },
  created() {
    this.load();
  },
};
</script>

<style scoped></style>
