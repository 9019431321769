<template>
  <tr
    :class="rowStatus"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="$emit('clickedTrack')"
  >
    <td class="sideIcon" @click="$emit(isPlaying ? 'pauseTrack' : 'playTrack')">
      <div class="playing" v-if="isPlaying">
        <i class="fa fa-pause" />
      </div>

      <div class="notPlayingAndHover" v-else-if="hover || clicked">
        <i class="fa fa-play" />
      </div>

      <div class="number" v-else>
        {{ trackNumber }}
      </div>
    </td>
    <td class="title">
      {{ title }}
    </td>
    <td class="artist">
      {{ artist }}
    </td>
    <td class="album" v-if="album">
      {{ album }}
    </td>
    <td class="dateAdded" v-if="dateAdded">
      {{ dateAdd }}
    </td>
    <td class="duration">
      {{ duration }}
    </td>
  </tr>
</template>

<script>
export default {
  name: 'TrackRow',
  props: {
    trackNumber: String,
    isPlaying: Boolean,
    album: String,
    artist: String,
    title: String,
    dateAdded: Date,
    duration: String,
    url: String,
    clicked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    dateAdd() {
      return this.dateAdded.toString().substring(4, 15);
    },
    rowStatus() {
      return {
        row: true,
        clicked: this.clicked,
      };
    },
  },
  emits: ['clickedTrack'],
};
</script>

<style scoped>
.row > * {
  padding: 0.5em 0.5em;
  border: 0;
}

.sideIcon {
  font-size: 1.5em;
  width: 5%;
}

.title {
  width: 40%;
  text-align: left;
  font-size: 1.1em;
  font-weight: 700;
}
.artist {
  width: 30%;
  text-align: left;
}

.clicked {
  background-color: #bdd9fd;
  border: 0;
}
</style>
