<template>
  <table>
    <tr>
      <th>#</th>
      <th class="title">Title</th>
      <th class="artist">Artist</th>
      <th>Duration</th>
    </tr>
    <TrackRow
      v-for="track in tracks"
      :key="track.id"
      v-bind="track"
      :isPlaying="currentTrack && currentTrack.id === track.id && isPlaying"
      :clicked="track.id === clickedTrack"
      @play-track="play(track)"
      @pause-track="pause"
      @clicked-track="click(track)"
    />
  </table>
</template>

<script>
import TrackRow from '@/components/TrackRow/TrackRow.vue';
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'TrackTable',
  props: {
    tracks: Array,
  },
  data() {
    return { clickedTrack: '' };
  },
  components: {
    TrackRow,
  },
  methods: {
    ...mapMutations(['setIsPlaying', 'setCurrentTrack']),
    play(track) {
      this.setIsPlaying(true);
      this.setCurrentTrack(track);
    },
    pause() {
      this.setIsPlaying(false);
    },
    click(track) {
      this.clickedTrack = track.id;
    },
  },
  computed: {
    ...mapState({
      isPlaying: (state) => state.isPlaying,
      currentTrack: (state) => state.currentTrack,
    }),
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th {
  font-size: 1.5em;
}

.title,
.artist {
  text-align: left;
}
</style>
